<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">
              기준월
            </div>
            <ul class="content">
              <li class="item date">
                <input-date
                    v-model="stdMonth"
                    format="YYYY-MM"
                    depth="Year"
                    :notClear="true"
                />
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :is-shortcut-button="true"
              v-on:click.native="getReportViewData"
          >
            조회
          </erp-button>
        </div>
      </div>
      <div class="lookup-right">
        <ul class="lookup-button">
          <li class="print">
            <erp-button
                button-div="FILE"
                @click.native="onPrintClick"
            >
              인쇄
            </erp-button>
          </li>
        </ul>
      </div>
    </div>
    <div class="content-body">
      <ReportView ref="reportViewComponent" :isPopup="false"/>
    </div>
  </div>
</template>

<script>

import moment from "moment";
import ReportView from '@/components/common/report/ReportView';
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import ErpButton from "@/components/button/ErpButton.vue";
import {
  DATE_FORMAT_YYYY,
  DATE_FORMAT_YYYY_MM, DATE_FORMAT_YYYY_MM_DD, getFormattedDate,
} from '@/utils/date';
import {mapGetters} from "vuex";
import InputDate from "@/components/common/datetime/InputDate.vue";

export default {
  name: "DailyVisitPer",
  components: {
    ReportView,
    ErpButton,
    InputDate
  },
  async created() {
    this.stdMonth = moment().format(DATE_FORMAT_YYYY_MM);
  },
  computed: {
    ...mapGetters(["username"]),
  },
  async mounted() {
    await this.getReportViewData();
  },
  data() {
    return {
      stdMonth: moment().format(DATE_FORMAT_YYYY_MM),
    };
  },
  methods: {
    async getReportViewData() {
      const year = moment(this.stdMonth).format(DATE_FORMAT_YYYY);
      const month = moment(this.stdMonth).format("MM");

      // 날짜 계산
      const currentYearStart = moment(`${year}-${month}-01`).format(DATE_FORMAT_YYYY_MM_DD);
      const currentYearEnd = moment(`${year}-${month}-01`).endOf('month').format(DATE_FORMAT_YYYY_MM_DD);
      const lastYearStart = moment(`${year - 1}-${month}-01`).format(DATE_FORMAT_YYYY_MM_DD);
      const lastYearEnd = moment(`${year - 1}-${month}-01`).endOf('month').format(DATE_FORMAT_YYYY_MM_DD);

      // API 호출로 데이터 가져오기
      const {visitPer} = await GolfErpAPI.getReportDailyVisitPer(currentYearStart,currentYearEnd,lastYearStart,lastYearEnd);

      // 필터링 기준 날짜
      const daySplit = "15";  // 기준이 되는 날짜
      const currentYear = moment(currentYearStart).format("YY")+"년";
      const lastYear = moment(lastYearStart).format("YY")+"년";
      const title = `${month}월 내장객 일별 현황`;

      // 데이터 필터링 함수
      const filterDataByDate = (data, isBefore) => data.filter(item => {
        const [itemMonth, itemDay] = item.bsnDate.split("/");
        return itemMonth === month && (isBefore ? itemDay <= daySplit : itemDay > daySplit);
      });

      // 15일 이전과 이후 데이터 필터링
      const before15th = filterDataByDate(visitPer, true);
      const after15th = filterDataByDate(visitPer, false);

      //전반월 후반월 합계 정보
      const finalSummary = this.totalPairVisitData(before15th,after15th);

      //내장객 일변 현황 테이블
      const pairVisitData = this.pairVisitData(before15th,after15th,currentYear,lastYear);

      console.log(title);
      console.log("내장객 일별 현황:", pairVisitData);
      console.log("내장객 합산 현황:", finalSummary);

      const reportData = {
        reportJson: {
          jsonData: {pairVisitData,finalSummary},
          bsnDate : "일자:  "+ month,
          title : title,
          username: this.username,
          uniqueCode: this.$options.name,
        }
      };

      console.log(reportData);

      await this.$refs.reportViewComponent.postReport(
          reportData,
          `${this.$options.name}`,
      );
    },
    onPrintClick() {
      this.$refs.reportViewComponent.printReport();
    },
    replaceValidNumber(val){
      // NaN And Infinity to 0
      return (isNaN(parseInt(val)) || !isFinite(val)) ? '0' : val;
    },
    pairVisitData(beforeVisitPer,afterVisitPer,currentYear,lastYear) {

      // 결과를 저장할 배열
      const pairedData = [];

      // 두 배열 중 더 긴 길이를 기준으로 반복
      const maxLength = Math.max(beforeVisitPer.length, afterVisitPer.length);

      for (let i = 0; i < maxLength; i++) {
        const before = beforeVisitPer[i] || {};
        const after = afterVisitPer[i] || {};

        // 매칭된 데이터를 한 객체로 병합
        pairedData.push({
          currentYear: currentYear,
          lastYear: lastYear,
          BbsnDate: before.bsnDate || "",
          BcomName: before.comName || "",
          BlastYearTotVisitPer: before.lastYearTotVisitPer,
          BlastYearVisitPer: before.lastYearVisitPer,
          BvisitPer: before.visitPer,
          BtotVisitPer: before.totVisitPer,
          AbsnDate: after.bsnDate || "",
          AcomName: after.comName || "",
          AlastYearTotVisitPer: after.lastYearTotVisitPer,
          AlastYearVisitPer: after.lastYearVisitPer,
          AvisitPer: after.visitPer,
          AtotVisitPer: after.totVisitPer,
        });
      }

      return pairedData;
    },
    totalPairVisitData(before15th,after15th){
      // 주말 또는 공휴일 여부를 판단하는 함수
      const isWeekendOrHoliday = item => {
        return item.dwCode === '1' || item.dwCode === '7' || item.hldyFlag === true;
      };

      // 요약 계산 함수 (일수와 visitPer 합계 계산)
      const calculateSummary = (filteredData) => {
        const dayLength = filteredData.length;
        const visitPerTotal = filteredData.reduce((sum, item) => sum + item.visitPer, 0);
        return { dayLength, visitPerTotal };
      };

      // 주말/공휴일과 평일 데이터를 구분하고 요약하는 함수
      const categorizeData = (data) => {
        const weekendOrHoliday = data.filter(isWeekendOrHoliday);
        const weekday = data.filter(item => !isWeekendOrHoliday(item));
        return {
          weekendOrHoliday: calculateSummary(weekendOrHoliday),
          weekday: calculateSummary(weekday)
        };
      };

      // 전반 월과 후반 월 데이터를 주말/공휴일 및 평일로 나누어 요약
      const summaryBefore15th = categorizeData(before15th);
      const summaryAfter15th = categorizeData(after15th);

      // 전체 합산 계산
      const totalSummary = {
        weekendOrHoliday: {
          dayLength: summaryBefore15th.weekendOrHoliday.dayLength + summaryAfter15th.weekendOrHoliday.dayLength,
          visitPerTotal: summaryBefore15th.weekendOrHoliday.visitPerTotal + summaryAfter15th.weekendOrHoliday.visitPerTotal
        },
        weekday: {
          dayLength: summaryBefore15th.weekday.dayLength + summaryAfter15th.weekday.dayLength,
          visitPerTotal: summaryBefore15th.weekday.visitPerTotal + summaryAfter15th.weekday.visitPerTotal
        }
      };

      const flattenSummaryRow = (label, beforeSummary, afterSummary, totalSummary) => ({
        weekName: label,
        before15thDayLength: beforeSummary.dayLength,
        before15thVisitPerTotal: beforeSummary.visitPerTotal,
        after15thDayLength: afterSummary.dayLength,
        after15thVisitPerTotal: afterSummary.visitPerTotal,
        totalDayLength: totalSummary.dayLength,
        totalVisitPerTotal: totalSummary.visitPerTotal
      });

      // 최종 합산 결과를 평평한 구조로 변환
      const finalSummary = [
        flattenSummaryRow("주말ㆍ공휴일", summaryBefore15th.weekendOrHoliday, summaryAfter15th.weekendOrHoliday, totalSummary.weekendOrHoliday),
        flattenSummaryRow("평    일", summaryBefore15th.weekday, summaryAfter15th.weekday, totalSummary.weekday)
      ];

      return finalSummary;
    },
  }

};
</script>
